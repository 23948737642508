<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";

import { userGridData } from "./data-user";

/**
 * Contacts-grid component
 */
export default {
    components: { Layout, PageHeader },
    data() {
        return {
            userGridData: userGridData,
            title: "Agents de Traitement",
            items: [
                {
                    text: "Paramétrage",
                    href: "/settings",
                },
                {
                    text: "Agents de traitement",
                    active: true,
                },
            ],
        };
    },
    methods: {
        searchFilter(e) {
      const searchStr = e.target.value;
      this.userGridData = userGridData.filter((agent) => {
        return (
          agent.name.toLowerCase().search(searchStr.toLowerCase()) !== -1
        );
      });
    },
    },
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="d-flex justify-content-between">
                <div class="search-box ">
                <div class="position-relative">
                  <input
                    type="text"
                    class="form-control border-0"
                    placeholder="Search..."
                    @input="searchFilter($event)"
                  />
                  <i class="bx bx-search-alt search-icon"></i>
                </div>
              </div>
                <button class="btn btn-primary ms-auto mb-2" @click="$router.push('/setting/treatment-officers/add')">
                    <i class="bx bx-plus"></i> Ajouter un agent de traitement
                </button>
            </div>
        </div>
        <div class="row">
            <div v-for="user in userGridData" :key="user.id" class="col-xl-3 col-sm-6">
                <div class="card text-center">
                    <div class="card-body">
                        <div v-if="!user.image" class="avatar-sm mx-auto mb-4">
                            <span class="
                  avatar-title
                  rounded-circle
                  bg-soft bg-primary
                  text-primary
                  font-size-16
                ">{{ user.name.charAt(0) }}</span>
                        </div>
                        <div v-if="user.image" class="mb-4">
                            <img class="rounded-circle avatar-sm" :src="`${user.image}`" alt />
                        </div>
                        <h5 class="font-size-15 mb-1">
                            <a href="javascript: void(0);" class="text-dark">{{
                                    user.name
                            }}</a>
                        </h5>
                        <p class="text-muted">{{ user.designation }}</p>

                        <div>
                            <span class="badge font-size-11 m-1" :class="{
                                'bg-success': `${user.status}` === 'En service',
                                'bg-warning': `${user.status}` === 'Hors service',
                                'bg-danger': `${user.status}` === 'Congés',
                            }">{{ user.status }}</span>
                            <span class="badge font-size-11 m-1" :class="{
                                'bg-success': `${user.service}` === 'De jours',
                                'bg-warning': `${user.service}` === 'De nuit',
                                'bg-danger': `${user.service}` === 'Alternance',
                            }">{{ user.service }}</span>
                        </div>
                    </div>
                    <div class="card-footer bg-transparent border-top">
                        <div class="contact-links d-flex font-size-20">
                            <div class="flex-fill">
                                <router-link to="" title="Détails" class="action-icon text-success">
                                    <i class="mdi mdi-eye font-size-18"></i>
                                </router-link>
                            </div>
                            <div class="flex-fill">
                                <router-link to="" title="Modifier" class="action-icon text-primary">
                                            <i class="mdi mdi-pencil font-size-18"></i>
                                        </router-link>
                            </div>
                            <div class="flex-fill">
                                <router-link to="" title="Supprimer" class="action-icon text-danger">
                                            <i class="mdi mdi-trash-can font-size-18"></i>
                                        </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- end col -->
        </div>
     
    </Layout>
</template>